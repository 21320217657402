import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components"
import { Button, Card, CardContent} from "@material-ui/core";
import globalhitssImg from '../../../images/source/Logo-Hitss-Latam-rojo.png';
import { Link } from "gatsby-theme-material-ui";

const Styled = styled.div`
 .title {
    width: 100%;
    padding: '0px 70px';
    color: #000000;
    font-family: Questrial;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 0;
    
    @media (max-width: 780px) {
        padding: 0px 10px;
    }
 }
 .linkAccess{
    margin: 0 auto;
    height: 67px;
    max-width: 500px;
    border-radius: 33.5px;
    background-color: #24CC65;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    .icon {
        width: 70px;
        margin-left: 5px;
        svg {
            height: 40px;
            width: 71.11px;
        }
    }
    .desc {
        padding-right: 15px;
        p {
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            margin: 0;
            text-decoration: none;
            a {
                color: white !important;
            }
        }
    }
 }
 .formControl{
     min-height: 65px;     
     .form-field:after {
        border-bottom-color: #f44336 !important;
    }
 }
 .submit{
    color: #FFFFFF;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    height: 40px;
    background-color: #A5BE01;
 }
 .form {
    margin-bottom: 0;
 }
`

const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const EncuestaOpc = () => {
    const [loading, setLoading] = useState(false);
    const [cargandoDatos, setCargandoDatos] = useState(true);
    const [data, setData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    const onHandleDownload = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                // console.error('No se encontró el token en el localStorage.');
                return;
            }

            const response = await fetch(`${URL}/survey/download`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const url = window.URL.createObjectURL(await response.blob());
                const a = Object.assign(document.createElement('a'), { href: url, download: `Encuesta_${new Date().toLocaleDateString()}.xlsx` });
                document.body.appendChild(a).click();
                a.remove();
            } else {
                // console.error('Error en la descarga:', response);
            }
        } catch (error) {
            // console.error('Error al comunicarse con el servidor:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await fetch(`${URL}/survey/encuestas`);
                const json = await response.json();

                if (isMounted) {
                    if (json.ok) {
                        const limitedData = json.data.reverse().map((obj) => ({
                            id_encuesta: obj.id_encuesta,
                            product_owner: obj.product_owner,
                            producto: obj.producto,
                            equipo_preventa: obj.equipo_preventa,
                            fecha_envio_encuesta: new Date(obj.fecha_envio_encuesta),
                            nombre_cliente: obj.nombre_cliente,
                            nombre_encuestado: obj.nombre_encuestado,
                            fecha_encuesta_completada: obj.fecha_encuesta_completada ? new Date(obj.fecha_encuesta_completada) : '-'
                        }));
                        setData(limitedData);
                    } else {
                        throw new Error("Error del servidor al intentar buscar encuestas.");
                    }
                }
            } catch (error) {
                console.error('Error consiguiendo datos:', error);
            } finally {
                if (isMounted) {
                    setCargandoDatos(false);
                }
            }
        };
        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let isMounted = true;

        const isAdminValidation = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${URL}/login/validarAdmin`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token })
                });

                const responseData = await response.json();

                if (isMounted) {
                    if (!responseData.ok) {
                        // console.error('Error al validar el token:', responseData);
                        setIsAdmin(false);
                    } else {
                        setIsAdmin(true);
                    }
                }
            } catch (error) {
                console.error('Error al validar el token:', error);
            }
        };
        isAdminValidation();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <Styled>
            <div className="ContactForm" style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ width: '800px', padding: '30px', margin: '50px auto' }}>
                    <CardContent>
                        <div style={{ textAlign: 'center' }}>
                            <img src={globalhitssImg} alt="Icono Hitss" width="50%" height="50%"  style={{ marginBottom: '5%', marginTop: '-5%' }}/>
                            {isAdmin ? <p style={{ fontSize: '20px', marginTop: '-50px', fontWeight: 'bold' }}>Seleccione una opción:</p> : <></>}
                        </div>
                        <Link to={`/encuesta-de-satisfaccion/encuesta-formulario`} style={{ marginBottom: '5%', display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                            <Button
                                type="button"
                                variant="contained"
                                className="submit"
                                style={{ marginTop: '0', display: 'inline-flex', backgroundColor: '#269094', color: '#fff', fontSize: '16px', width: '200px' }}
                            >
                                Generar encuesta
                            </Button>
                        </Link>
                        {isAdmin ? <Link style={{ display: 'flex', justifyContent: 'center', textDecoration: 'none' }}>
                            <Button
                                type="button"
                                variant="contained"
                                className="submit"
                                style={{ marginTop: '0', backgroundColor: '#269094', color: '#fff', fontSize: '16px', width: '200px' }}
                                onClick={onHandleDownload}
                                disabled={loading}
                            >
                                {loading ? 'Descargando...' : 'Descargar Excel'}
                            </Button>
                        </Link> : <></>}
                    </CardContent>
                </Card>
            </div>
            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                {cargandoDatos ?
                    <p style={{ fontSize: '200%', fontWeight: 'bold' }}>Cargando...</p> : (
                        <div style={{ width: '90%', overflowX: 'auto' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Producto Owner</th>
                                        <th>Producto</th>
                                        <th>Equipo Preventa</th>
                                        <th>Envio de encuesta</th>
                                        <th>Cliente</th>
                                        <th>Encuestado</th>
                                        <th>Completada</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(({ id_encuesta, product_owner, producto, equipo_preventa, fecha_envio_encuesta, nombre_cliente, nombre_encuestado, fecha_encuesta_completada }) => (
                                        <tr key={id_encuesta}>
                                            <td>{id_encuesta}</td>
                                            <td>{product_owner}</td>
                                            <td>{producto}</td>
                                            <td>{equipo_preventa}</td>
                                            <td>{fecha_envio_encuesta.toLocaleString('es-AR', { timeZone: 'UTC', hour12: false })}</td>
                                            <td>{nombre_cliente}</td>
                                            <td>{nombre_encuestado}</td>
                                            <td>{fecha_encuesta_completada.toLocaleString('es-AR', { timeZone: 'UTC', hour12: false })}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
            </div>
        </Styled>
    );
};

export default EncuestaOpc;